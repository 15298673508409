import React, {useState} from 'react'
//import { Router } from 'react-router-dom'
import { Typography, TextField,Button } from '@material-ui/core'
//import axios from 'axios'
import useRequest from './UseRequest'

function Unsubscribe() {
    const [email, setEmail] = useState('')
    //const [errors, setErros] = useState(null)

    const { doRequest, errors } = useRequest({
        url: '/api/unsubscribe',
        method: 'post',
        body: { email },
        //onSuccess: () => Router.push('/') // another prop in object argument

    }
    )

    async function onClick(){
        doRequest()
        setEmail('')
    } //need email to dissapear

    return (
        <div>
            <Typography variant="h5" align="center" color="textSecondary" component="p">Enter your email to unsubscribe from our mailing list</Typography>
            <TextField label="enter emaill" onChange={e=> setEmail(e.target.value)} value={email} />
            <br/>
            {errors}
            <Button style={{marginTop:10}} variant='contained' color='primary' onClick={onClick}>Submit</Button>
        </div>
    )
}

export default Unsubscribe