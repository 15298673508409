import React from 'react'
import {  Checkbox, FormGroup, FormControlLabel, Typography, Button } from '@material-ui/core'

const CitySelectorLoading = () => {

    const cityList = ["Halton Hills", "Puslinch", "Kitchener", "Milton", "Waterloo", "Cambridge", "Guelph/Eramosa", "Guelph", "Hamilton", "Erin", "Centre Wellington"]

    const renderCities = () => {
        return cityList.map(city => {
           return <FormControlLabel key={city} control={<Checkbox name={city} color="primary"/>} label={city} />
        })
    }


    return (
        <div >
            <Typography variant="h4" align="center" >Select cities</Typography>
            <FormGroup style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}} row  >
                {renderCities()}
            </FormGroup>
            <Button variant='contained' >All/NONE</Button>
        </div>
    )

}

export default CitySelectorLoading