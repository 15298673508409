import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AppBar, Typography, Toolbar, Button, Container } from '@material-ui/core';
import useStyles from './styles'
import axios from 'axios';

const Header = ({ signedIn, user }) => {
    const classes = useStyles();
    const history = useHistory()
    const handleSignout = () => {
        axios.get('/api/signout')
        history.push('/signin')
        window.location.reload()
    }

    const renderLogin = () => {
        try {
            if (signedIn && user.subscriptionType === 0) {
                return (
                    <span style={{ right: '10%', position: 'absolute', bottom: '1px' }} >
                        {/*  <Link className={classes.linkButton} to="/demo" >DEMO</Link>*/}
                        <Link className={classes.linkButton} to="/pricing" >PRICING</Link>
                        <Link className={classes.linkButton} to="/about" >ABOUT</Link>
                        <Button style={{ color: 'white', width: '10 rem' }} onClick={handleSignout} >SignOut</Button>
                    </span>
                )
            }
        } catch (error) {

        }

        if (signedIn) {
            return (
                <span style={{ right: '10%', position: 'absolute', bottom: '1px' }} >
                    <Link className={classes.linkButton} to="/account" >ACCOUNT</Link>
                    {/*                     <Link className={classes.linkButton} to="/pricing" >PRICING</Link>
                    <Link className={classes.linkButton} to="/about" >ABOUT</Link> 
                    <Button style={{ color: 'white', width: '10 rem' }} onClick={handleSignout} >SignOut</Button>*/}
                    <Button className={classes.linkButton} onClick={handleSignout} >SignOut</Button>
                </span>
            )
        } else {
            return (
                <span style={{ right: '10%', position: 'absolute', bottom: '1px' }}>
                    {/*                 <Link className={classes.linkButton} to="/trial" >TRIAL</Link>
                <Link className={classes.linkButton} to="/demo" >DEMO</Link>
                <Link className={classes.linkButton} to="/pricing" >PRICING</Link>
                <Link className={classes.linkButton} to="/about" >ABOUT</Link> */}
                    <Button style={{ color: 'white' }} href='/signin'>SignIn</Button>
                </span>
            )
        }
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" style={{ marginBottom: '5px', minHeight: "112px" }}>
                <Toolbar style={{ textColor: 'white' }}>
                    <Container  >
                        <span>
                            <Link to="/" className={classes.linkButton} >
                                <Typography variant='h2' style={{ flexGrow: 1, }}>Local TREB</Typography>
                                {/* <Typography variant='span' >Trial</Typography> */}
                            </Link>
                        </span>
                        {renderLogin()}
                    </Container>


                </Toolbar>
            </AppBar>
        </div>
    )

}

export default Header

//                    <Typography variant="h2"  color="textSecondary">Sign in</Typography>