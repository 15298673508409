import axios from 'axios'
import { useState } from 'react'

function useRequest({ url, method, body, onSuccess }) {
    const [errors, setErrors] = useState(null)

    async function doRequest() {
        try {
            setErrors(null) // reset errors so they don't show up after you fix
            const response = await axios[method](url, body)
            if(onSuccess) { // if this call back exists/was provided, with data provided as an arg (if it needs to be utilized)
                onSuccess(response.data)
            }
            return response.data
        } catch (err) {
            console.log("ERororror",err.response)
            setErrors(
                <div >
                <p style={{color: 'red'}}>Oooops</p>
                <p>
                    {err.response.data}
                </p>
            </div>
            )
        }
    }
    return {doRequest, errors}
}
export default useRequest