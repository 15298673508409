import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({ //arg is a callback that returns a styling object
    container: { // create a class called container
        backgroundColor: theme.palette.background.paper, // define style using theme
        padding: '20px'
    },
    selectedGrid: {
        backgroundColor: 'gray'

    },
    radialGreen: {
      background: 'radial-gradient(circle, transparent, #00ff00)'
    },
    flashGreen: {
      animation: '$flashGreenEffect 1s infinite alternate', // 2s for duration, adjust as needed
      backgroundColor: 'transparent', // initial color
    },
    '@keyframes flashGreenEffect': {
      '0%': {
        backgroundColor: 'transparent',
      },
      '100%': {
        backgroundColor: '#00ff00', // green color when flashing
      },
    },
    mls: {margin: '0px'},
    paper: {
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: "white",
        height: '50px',
        justifyContent: 'center',
        fontWeight: 'bold',
        margin: '5px',
        padding: '5px 10px'
      },
      paperSoon: {
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#79ade0',
        color: "white",
        height: '50px',
        justifyContent: 'center',
        fontWeight: 'bold',
        margin: '5px',
        padding: '5px 10px'
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      root: { flexGrow: 1},
      linkButton : {
        color: 'white',
        textDecoration: 'none',
        marginLeft: '10px',
        marginRight: '10px',
        fontWeight: "bold",

      },
      link: {
        margin: theme.spacing(1, 1.5),
      },
      heroContent: {
        padding: theme.spacing(1, 0),
      },
      cardHeader: {
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
      },
      cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
      },
      paperSignIn: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        }
    },
    tooltip: {
      '& .MuiTooltip-tooltip': {
        maxWidth: '400px',
      },
    },
    FormGroup:  {
      
      borderStyle: 'none',
      backgroundColor: 'white',
      willChange: 'opacity, transform',
      boxShadow: '0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08)',
      
    }

}));

export default useStyles