import React, { useEffect, useState } from 'react';
import Header from './Header'
import Landing from './Landing'
import Copyright from './Copyright';
import SignIn from './SignIn';
//import Pricing from './Pricing';
//import PricingFlex from './PricingFlex';
//import Demo from './Demo'
import About from './About'
import Account from './Account';
import Terms from './Terms'
import Delete from './Delete'
import Payment from './Payment'
import { Container, CssBaseline } from '@material-ui/core';
import { BrowserRouter, Route } from 'react-router-dom'
import { Box } from '@material-ui/core';
import axios from 'axios';
import Privacy from './Privacy';
//import Trial from './Trial'
//import TrialOver from './TrialOver'
//import Free from './Free';
import Unsubscribe from './Unsubscribe'


const App = () => {
    const [signedIn, setSignedIn] = useState(false)
    const [user, setUser] = useState(null)

    const checkSignedIn = async () => {
        await axios.get('/api/agent').then(user => {
            user.data ? setSignedIn(true) : setSignedIn(false)
            setUser(user.data)
        })
    }

    useEffect(() => {
        checkSignedIn()
    }, [signedIn])

    //const REGULAR_PRICE = ["10", "20", "30", "99", "199", "299"]
    //const DISCOUNT_PRICE = ["5", "10", "15", "49", "99", "149"]

    // {/* <Route path='/' exact render={props => <Landing {...props} signedIn={signedIn} user={user} />}  /> */}
    //   {/* <Route path='/listings' exact component={Free}  /> */}

    return (
        <>
            <CssBaseline />
            <BrowserRouter>
                <Header signedIn={signedIn} user={user} />
                <Container style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} >

                    <Route path='/' exact render={props => <Landing {...props} signedIn={signedIn} user={user} />} />
                    {/* <Route path='/' exact component={Trial} /> */}
                    <Route path='/signin' exact component={SignIn} />
                    {/* <Route path='/pricing' exact component={Pricing}/> */}
                    {/* <Route path='/pricing' exact render={props => <PricingFlex {...props} currentPriceStringArray={DISCOUNT_PRICE} discount={true}  />}  /> */}

                    {/* <Route path='/demo' exact component={Demo}/> */}
                    <Route path='/about' exact component={About} />
                    <Route path='/account' exact render={props => <Account {...props} signedIn={signedIn} user={user} />} />
                    <Route path='/privacy' exact component={Privacy} />
                    <Route path='/delete' exact component={Delete} />
                    <Route path='/terms' exact component={Terms} />
                    <Route path='/payment' exact render={props => <Payment {...props} signedIn={signedIn} user={user} />} />
                    {/* <Route path='/trial' exact component={TrialOver}  /> */}

                    <Route path='/unsubscribe' exact component={Unsubscribe} />
                    <Box mt={5} style={{ marginTop: 'auto' }} >
                        <Copyright />
                    </Box>
                </Container >
            </BrowserRouter>


        </>
    )
}


export default App