import convertNumToMonth from "./convertNumToMonth";

export default function dateToString(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const monthString = convertNumToMonth(month);
    return `${monthString} ${day}, ${year}`;
}
