const convertTier = num => {
    switch (num) {
        case 1:
            return 1;
        case 2:
            return 1;
        case 3:
            return 2;
        case 4:
            return 2;
        case 5:
            return 3;
        case 6:
            return 3;
        default:
            return '';
    }
}
export default convertTier;