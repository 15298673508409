import { Typography } from "@material-ui/core";
import React from "react";

const Privacy = () => {
    return (
        <div>
            <Typography variant='h4' >Privacy Policy</Typography>
            <Typography variant='h5'>Interpretation</Typography>
            <Typography >The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</Typography>
            <Typography variant='h5'>Definitions</Typography>
            <Typography>
                Account means a unique account created for You to access our Service or parts of our Service.
                <br/>
                Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Local Treb, 220 King St N, Waterloo.
                <br/>
                Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
                <br/>
                Country refers to: Canada
                <br/>
                Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                <br/>
                Personal Data is any information that relates to an identified or identifiable individual.
                <br/>
                Service refers to the Website.
                <br/>
                Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
                <br/>
                Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                <br/>
                Website refers to LocalTREB, accessible from https://localtreb.com
                <br/>
                You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            </Typography>
            <Typography variant='h5' >Collecting and Using Your Personal Data</Typography>
            <Typography >While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information includes name and email address. Only session cookies are used. We will not share your data with any third parties</Typography>
            <Typography variant='h5' >Retention of Your Personal Data</Typography>
            <Typography >The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</Typography>
            <Typography variant='h5' >Transfer of Your Personal Data</Typography>
            <Typography >Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</Typography>
            <Typography variant='h5' >Deleting Your Personal Data</Typography>
            <Typography>You can delete our personal data by visiting either localtreb.com/account and clicking the DELETE ACCOUNT button, or localtreb.com/delete and entering in your email. NOTE: You must cancel your subscription prior to doing this, which can also be done at localtreb.com/account</Typography>
            <Typography variant='h5' >Links to Other Websites</Typography>
            <Typography >Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.

We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</Typography>
            <Typography variant='h5' >Changes to this Privacy Policy</Typography>
            <Typography >We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.

We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.

You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Typography>
            <Typography variant='h5' >Contact Us</Typography>
            <Typography>If you have any questions about this Privacy Policy, You can contact us:

By email: info@localtreb.com</Typography>
        </div>
    )
}

export default Privacy