const convertPrice = num => {
    switch (num) {
        case 1:
            return 5;
        case 2:
            return 49;
        case 3:
            return 10;
        case 4:
            return 99;
        case 5:
            return 15;
        case 6:
            return 149;
        default:
            return '';
    }
}
export default convertPrice;