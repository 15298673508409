import React from 'react'
import { Typography, Checkbox, Card, CardMedia, CardHeader, Box, Button } from '@material-ui/core';
import dateToString from './helpers/dateToString'
//import { Link } from 'react-router-dom';
import useStyles from './styles';
import changeType from './helpers/changeType';

import axios from 'axios'

/* function bedsOrLand(listing) {
    switch (listing.use) {
        case "Residential":
            return `${listing.beds} beds`;
        case "Land":
            return listing.landSize;
        case "Farm":
            return listing.landSize;;
        default:
            return `${listing.beds} beds`;
    }
} */
function bedsOrLand(listing) {
    const bedOrBeds = listing.beds === "1" ? "bed" : "beds";

    switch (listing.use) {
        case "Residential":
            return `${listing.beds ?? "0"} ${bedOrBeds}`;
        case "Land":
            return listing.landSize;
        case "Farm":
            return listing.landSize;
        default:
            return `${listing.beds ?? "0"} ${bedOrBeds}`;
    }
}


/* function bathFrontNull(listing) {
    switch (listing.use) {
        case "Residential":
            return `${listing.baths} baths`;
        case "Land":
            return null
        case "Farm":
            return `${listing.beds ?? "0"} beds, ${listing.baths ?? "0"} baths`;
        default:
            return `${listing.baths ?? "0"} baths`;
    }
} */
function bathFrontNull(listing) {
    const bathOrBaths = listing.baths === "1" ? "bath" : "baths";

    switch (listing.use) {
        case "Residential":
            return `${listing.baths ?? "0"} ${bathOrBaths}`;
        case "Farm":
            const bedOrBeds = listing.beds === "1" ? "bed" : "beds";
            return `${listing.beds ?? "0"} ${bedOrBeds}, ${listing.baths ?? "0"} ${bathOrBaths}`;
        case "Land":
            return null;
        default:
            return `${listing.baths ?? "0"} ${bathOrBaths}`;
    }
}



const TrebGrid = ({ filteredListingsByCity, toggleChecked, checked, allCities, setIsLoading, user, isLoading }) => {
    const classes = useStyles();
    async function hideListing(mls) {
        console.log(mls)
        const res = await axios.post('https://www.localtreb.com/api/hide', { mls: mls })
        console.log(res)
    }
    //console.log("filteredListingsByCity", filteredListingsByCity)
    const renderGridItems = () => {
        return filteredListingsByCity.map(listing => { //onLoad={()=>setIsLoading(false)}
            function getTypeNameByUse(use) {
                switch (use) {
                    case "Residential":
                        if (listing.type === 'Mobile Home') return "Mobile Home"
                        if ((listing.ownership === "Condominium/Strata") || listing.ownership === "Condominium") {
                            if (listing.type === "Row / Townhouse") return "Condo Townhouse"
                            if (listing.type === "Apartment") return "Apartment Condo"
                        }
                        if (listing.ownership === "Leasehold Condo/Strata") return "Land Lease"
                        if (listing.type === "Duplex") return "Duplex"
                        if (listing.type === "Triplex") return "Triplex"
                        if (listing.type === "Fourplex") return "Fourplex"
                        if (listing.type === "Multi-Family") return "Multi-Unit"
                        if (listing.type === "Row / Townhouse") return "Freehold Townhouse"
                        if ((listing.stories === "1") && (listing.type !== "Apartment")) return "Bungalow"
                        if (listing.stories === "1.5") return "1.5 Storey"
                        if (listing.stories === "2") return "2 Storey"
                        if (listing.stories === "2.5") return "2.5 Storey"
                        if (listing.stories === "3") return "3 Storey"
                        return changeType(listing.type); // Assuming `changeType` and `listing` are available in this scope
                    case "Farm":
                        if (listing.farmType === "Farm") return "Farm"
                        if (listing.farmType.length > 5) return `Farm - ${listing.farmType}`
                        break
                    case "Land":
                        return "Land";
                    case "Unknown":
                        return "Apartment Building"
                    default:
                        return "Unknown"; // default case if use doesn't match any known IDs
                }
            }

            return (
                <Box
                    onLoad={() => setIsLoading(false)}
                    key={listing.mls} p={1} style={{ height: 515, width: 300, marginLeft: 8, }} >

                    <Card name={listing.mls} variant="outlined" style={{ height: 508, width: 300 }}  >


                        <a href={listing.link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <CardHeader title={listing.address} subheader={listing.city} style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} />
                        </a>


                        <CardMedia style={{ objectFit: "contain", maxWidth: 300, height: 220 }}     //{ height:"auto", minWidth:"100%" }}
                            component="img"
                            alt="Listing"
                            //image={`data:image/png;base64, ${ listing.cropped || listing.pic}`}
                            image={listing.googlePic}
                            // image={`https://storage.googleapis.com/localtreb/${listing.mls}.jpg`}
                            title={listing.mls}
                            loading='lazy'
                        />

                        <Typography variant="h6" style={{ paddingLeft: 15 }}>
                            <b>
                                {listing.price}
                            </b>
                        </Typography>
                        <Typography style={{ paddingLeft: 15 }}>
                            {getTypeNameByUse(listing.use)} {listing.size ? ` | ${listing.size}` : ""}
                            <br />
                            {bedsOrLand(listing)}
                            <br />
                            {bathFrontNull(listing)}
                            <br />
                            Listed {dateToString(listing.da)}
                        </Typography>
                        <Typography style={{ paddingLeft: 15 }} id="mls" className={classes.mls}>{listing.mls}</Typography>


                        <div align="center">
                            {user?.email === "greigturvey@gmail.com" ? <Button onClick={() => hideListing(listing.mls)} variant="contained" color="secondary">Hide</Button> : ""}
                            <Checkbox name={listing.mls} color="primary" checked={checked ? checked.includes(listing.mls) : false} onClick={(e) => toggleChecked(e)} />
                        </div>


                    </Card>

                </Box>

            )
        })
    }

    if (filteredListingsByCity.length < 1 && !isLoading) {
        return <Typography align='center' variant="h4">No listings to display with this criteria </Typography>
    }

    return (


        <div style={{ display: 'flex', flexWrap: "wrap", alignContent: "flex-start", justifyContent: "start", marginTop: 15 }}>
            {renderGridItems()}
        </div>


    )
}

export default TrebGrid


