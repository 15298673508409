import React, { useState, useEffect } from 'react'
import { TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem, Divider, FormControlLabel, Checkbox, FormGroup, TextareaAutosize } from '@material-ui/core'
import axios from 'axios'
import convertPrice from './helpers/convertPrice';
import convertTier from './helpers/convertTier';
import convertPaySchedule from './helpers/convertPaySchedule';
import convertCities from './helpers/convertCities';
import dateToString from './helpers/dateToString';
import { useHistory } from 'react-router-dom';

const EditAccount = ({ user, subscription }) => {
    const allRegions = ['Kitchener', 'Waterloo', 'Cambridge - Hespeler - Preston', 'Guelph', 'Hamilton Region - Stoney Creek', 'Brantford', 'Brant County', 'Woolwich - Wellesley - Wilmot - N Dumfies', 'Puslinch - Flamborough - Halton Hills', 'Guelph/Eramosa - Center Wellington - Wellington North - Erin - Minto', 'Woodstock', 'Oxford County']
    const [cities, setCities] = useState(user.cities)
    const [subscriptionType, setSubscriptionType] = useState(user.subscriptionType)
    const [paySchedule, setPaySchedule] = useState(convertPaySchedule(user.subscriptionType))
    const [tier, setTier] = useState(convertTier(user.subscriptionType)) // 1, 2 or 3 for single, triple, all
    const [price, setPrice] = useState(convertPrice(user.subscriptionType))
    const [newEmail, setNewEmail] = useState(user.email)
    const [change, setChange] = useState(false)
    const [cancelSub, setCancelSub] = useState(false)
    const [cancelSubReason, setCancelSubReason] = useState('')
    const [successEmail, setSuccessEmail] = useState(false);
    const [successCancelSub, setSuccessCancelSub] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (paySchedule === 'monthly' && tier === 1) {
            setPrice(5)
            setCities([])
            setSubscriptionType(1)
        }
        if (paySchedule === 'monthly' && tier === 2) {
            setPrice(10)
            setCities([])
            setSubscriptionType(3)
        }
        if (paySchedule === 'monthly' && tier === 3) {
            setPrice(15)
            setCities(allRegions)
            setSubscriptionType(5)
        }
        if (paySchedule === 'yearly' && tier === 1) {
            setPrice(49)
            setCities([])
            setSubscriptionType(2)
        }
        if (paySchedule === 'yearly' && tier === 2) {
            setPrice(99)
            setCities([])
            setSubscriptionType(4)
        }
        if (paySchedule === 'yearly' && tier === 3) {
            setPrice(149)
            setCities(allRegions)
            setSubscriptionType(6)
        }// eslint-disable-next-line
    }, [paySchedule, tier])

    const updateEmail = async (e) => {
        const res = await axios.post('stripe/update/email', {
            newEmail,
            _id: user._id,
            stripeId: user.stripeId
        })
        console.log('res', res)
        if (res.data.success) {

            setSuccessEmail(true)
        }
        setNewEmail('')
    }

    const toggleChange = (state, stateSetter) => {
        stateSetter(!state)
    }
    //const classes = useStyles()
    const [success, setSuccess] = useState(false)

    const handleChangePaySched = (e) => {
        setPaySchedule(e.target.value)
    }
    const handleChangeTier = (e) => {
        setTier(e.target.value)
    }

    const toggleCities = (e) => {
        const target = e.target.checked; 
        const name = e.target.name; 
      if (target) {
        setCities([...cities, name]);
      } else {
        setCities(cities.filter(city => city !== name));
      }
    }

    const handleReset = () => {
        if (tier !== 3) {
            setCities([])
        }
    }

    const renderCities = () => {
        return allRegions.map(city => {
           return <FormControlLabel  key={city} control={<Checkbox name={city} disabled={(tier===3) || (tier === 1 && cities.length >= 1) || (tier === 2 && cities.length >= 3)} color="primary"  checked={cities.includes(city)}/>} label={`${city}`} />
        })
    }

    const renderSelectMessage = () => {
        if (tier === 1) {
            return "Select 1 city or region. Click Reset or change tier to change your choice."
        }
        if (tier === 2) {
            return "Select 3 cities or regions. Click Reset or change tier to change your choice."
        }
        if (tier === 3) {
            return "You have selected all of the cities and regions. Click Reset or change tier to change your choice."
        }
    }

    const handleChangeSubscription = async () => {
        const res = await axios.post('/stripe/change', {
            _id: user._id,
            subscriptionId: user.subscriptionId,
            cities: convertCities(cities),
            subscriptionType
        })
        if (res.data.success) {
            setSuccess(true)
        }
    }

    const handleCancelSub = async () => {
        const res = await axios.post('/stripe/cancel', {
            _id: user._id,
            subscriptionId: user.subscriptionId,
            cancelSubReason,
        })
        if (res.data.success) {
            setSuccessCancelSub(true)
        }
        setCancelSubReason('')
        history.push('/demo')
        window.location.reload()
    }

    const handleDeleteAccount = () => {
        axios.post('/api/delete_user/', { id: user._id })
    }
    const monthOrYear = () => {
        return paySchedule === 'monthly' ? 'month' : 'year'
    }

    console.log('successEmail', successEmail)

    return (
        <div>
            <br/>
            <form>
             <TextField value={newEmail} type='email' onChange={(e)=>setNewEmail(e.target.value) } id="new-email" label="New email?" style={{minWidth: 350}} />
             <Button size='small' disabled={successEmail} variant='outlined' style={{verticalAlign: "bottom"}} onClick={e => updateEmail(e)} >Change Email</Button>
            </form>
            {successEmail ? <Typography style={{color: 'green'}}>Email updated successfully</Typography> : '' }
            <br/>
            <Divider />
            <br/>
            <Button onClick={() => toggleChange(change, setChange)} variant='outlined' size='small'>Change Subscription</Button>
            <br/>
            {change ? 
                <div>
                    {!success ?
                <>
                    <Typography variant='h6'>Please confirm subscription details</Typography>
                    <Typography variant='subtitle2'>Your new subcription will become active immediately. Changes 
                    will be pro-rated, so your next payment on {dateToString(new Date(subscription.current_period_end))} 
                    will be higher or lower than the regular quoted amount, depending on whether you switch to/from a more 
                    expensive plan. You are not able to change your plan more than once per month.</Typography>
                    <FormControl style={{ minWidth: 183 }}>
                    <InputLabel id="paymentSchedule">Payment Schedule</InputLabel>
                    <Select
                        id="pay-schedule"
                        value={paySchedule}
                        onChange={handleChangePaySched}
                        defaultValue={paySchedule}
                    >
                        <MenuItem value={'monthly'}>Monthly</MenuItem>
                        <MenuItem value={'yearly'}>Yearly</MenuItem>
                    </Select>
                    </FormControl>
                    <>
                        <FormControl style={{ minWidth: 183 }}>
                            <InputLabel id="tier">Tier</InputLabel>
                            <Select
                                id="tier"
                                value={tier}
                                onChange={handleChangeTier}
                            >
                                <MenuItem value={1}>Single</MenuItem>
                                <MenuItem value={2}>Triple</MenuItem>
                                <MenuItem value={3}>All</MenuItem>
                            </Select>

                        </FormControl></>
                        <Typography variant="h6" display="inline"  style={{verticalAlign: 'bottom', paddingLeft: 10}}>
                            Price: ${price} per {monthOrYear()} (+HST)
                        </Typography>
                        <br/>
                        <br/>
                            <Divider />
                        <br/>
                        <Typography variant="h6">{renderSelectMessage()}</Typography>
                        <FormGroup row onClick={e => toggleCities(e)} >
                            {renderCities()}
                        </FormGroup>
                        <Button color="secondary" variant='outlined' onClick={handleReset}>Reset</Button>
                        <Button color="secondary" style={{marginLeft: 15}} variant='contained' onClick={handleChangeSubscription}>Confirm Subscription Change</Button>
                        <br/>
                        <br/>
                        </>
                        :
                    <div>
                        <h2>You have successfully changed your subscription.</h2>
                    </div>
                
                }
                </div>
             : ''}
            <br/>
            <Divider/>
            <br/>
            
            <Button color="secondary" variant='contained' disabled={cancelSub || change} onClick={() => toggleChange(cancelSub, setCancelSub)}>Cancel Subscription</Button>
            <br/>
            <Typography variant='subtitle2'>If you cancel now, your subscription will remain active until {dateToString(new Date(subscription.current_period_end))}. You will not be charged again.</Typography>
            <br/>
            {cancelSub ? 
                <div>
                    <Typography>Can you tell us why you're cancelling?</Typography>
                    <TextareaAutosize value={cancelSubReason} onChange={e => setCancelSubReason(e.target.value)} style={{minWidth: 250}} minRows={5}></TextareaAutosize>
                    <br/>
                    <Button disabled={(user.subscriptionType === 0) || successCancelSub } variant="contained" color="secondary" onClick={handleCancelSub}>Confirm Cancellation</Button>
                    <br/>
                    <br/>
                    {successCancelSub ? <Typography>Your subscription has been cancelled.</Typography> : ''} 
                    <br/>
                </div> 
                : ''
                    }
            <Divider/>
            <br/>
            <Button variant="contained" onClick={handleDeleteAccount} disabled={user.subscriptionType !== 0} color='secondary'>Delete Account</Button>
            <br/>
            <Typography variant='subtitle2'>Note that you must cancel any subscriptions prior to deleting your account. If you delete your account, 
            you will not be able to browse listings, even if you're in the middle of a billing cycle and your subscription 
            is still technically active. We suggest only deleting your account after cancelling your subscription and it
             runs out. We do not refund partial months.</Typography>
        </div>
    )
};

export default EditAccount