import React, { useEffect } from 'react'
import { Box, Checkbox, FormGroup, FormControlLabel, Typography, Button, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, useTheme } from '@material-ui/core'
import {ExpandMore} from "@material-ui/icons"
const CitySelector = ({ allCities, toggleAllCityState, toggleCityState, cityState, currentListings }) => {
    const renderCityCount = (city) => {
        const obj = {}
        for (let c of allCities) {
            obj[c] = 0
        }
        for (let listing of currentListings) {
            obj[listing.city] ++
        }
        return obj[city]
    }

    const renderCities = () => {
        return allCities.map(city => {
           return <FormControlLabel key={city} control={<Checkbox name={city} color="primary" checked={cityState.includes(city)}/>} label={`${city} (${renderCityCount(city)})`} />
        })
    }

    const theme = useTheme()
    const mobileBrowser = useMediaQuery(theme.breakpoints.down('sm')); // true if mobile browser
    const [expanded, setExpanded] = React.useState(!mobileBrowser)
    useEffect(() => {
        setExpanded(!mobileBrowser)
    }, [mobileBrowser])

/*     function listSelectedCities() {
        let displayString = ""
        for (const city of cityState) {
            displayString += `, ${city}`
        }
        return displayString.slice(2)
    } */

    return (
        <div >
            <Accordion expanded={expanded} onChange={(e, exp) => {setExpanded(exp)}} >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ flexWrap: "wrap"}}
                >
                    {/* //style={{flexShrink: "0" */}
                    <Typography variant={mobileBrowser ? "h6" : "h4"}  >Select Cities</Typography>
                    {/* <Typography style={{whiteSpace: 'nowrap', overflow: 'hidden', margin: "auto", textOverflow: "ellipsis" }} variant="subtitle1"  >{listSelectedCities()}</Typography> */}

                </AccordionSummary>
            <AccordionDetails>
            <Box>
                <FormGroup style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}} row onClick={e => toggleCityState(e)} >
                    {renderCities()}
                </FormGroup>
            </Box>
            <Box sx={{ height: "50px", marginTop: "auto"}} >
                <Button onClick={toggleAllCityState} variant='contained' >{`All/\nNone`}</Button>
            </Box>
            </AccordionDetails>   
      </Accordion>
        </div>
    )

}

export default CitySelector