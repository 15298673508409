import ContentLoader from "react-content-loader"

const LoadingCards = () => (
    <div style={{ display: 'flex', flexWrap: "wrap", alignContent: "flex-start", justifyContent: "start", marginTop:15}}>
    <ContentLoader style={{borderStyle:"solid", borderWidth:"1", borderColor:"lightgray", marginLeft:"7px", marginBottom: "7px"}} width={300} height={500} speed={3} backgroundColor={'#2788e8'} foregroundColor={'#f8f8ff'}>
        <rect x="5" y="28" rx="4" ry="4" width="220" height="26" />
        <rect x="5" y="65" rx="4" ry="4" width="80" height="14" />
        <rect x="5" y="98" rx="5" ry="5" width="290" height="218" />
        <rect x="5" y="325" rx="5" ry="5" width="150" height="18" />
        <rect x="5" y="354" rx="4" ry="4" width="130" height="15" />
        <rect x="5" y="380" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="405" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="430" rx="4" ry="4" width="170" height="15" />
        <rect x="5" y="455" rx="4" ry="4" width="100" height="15" />
        <rect x="130" y="475" rx="4" ry="4" width="15" height="15" />
    </ContentLoader>
    <ContentLoader style={{borderStyle:"solid", borderWidth:"1", borderColor:"lightgray", marginLeft:"7px", marginBottom: "7px"}} width={300} height={500} speed={3} backgroundColor={'#2788e8'} foregroundColor={'#f8f8ff'}>
        <rect x="5" y="28" rx="4" ry="4" width="270" height="26" />
        <rect x="5" y="65" rx="4" ry="4" width="105" height="14" />
        <rect x="5" y="98" rx="5" ry="5" width="290" height="218" />
        <rect x="5" y="325" rx="5" ry="5" width="130" height="18" />
        <rect x="5" y="354" rx="4" ry="4" width="150" height="15" />
        <rect x="5" y="380" rx="4" ry="4" width="100" height="15" />
        <rect x="5" y="405" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="430" rx="4" ry="4" width="170" height="15" />
        <rect x="5" y="455" rx="4" ry="4" width="100" height="15" />
        <rect x="130" y="475" rx="4" ry="4" width="15" height="15" />
    </ContentLoader>
    <ContentLoader style={{borderStyle:"solid", borderWidth:"1", borderColor:"lightgray", marginLeft:"7px", marginBottom: "7px"}} width={300} height={500} speed={3} backgroundColor={'#2788e8'} foregroundColor={'#f8f8ff'}>
        <rect x="5" y="28" rx="4" ry="4" width="230" height="26" />
        <rect x="5" y="65" rx="4" ry="4" width="80" height="14" />
        <rect x="5" y="98" rx="5" ry="5" width="290" height="218" />
        <rect x="5" y="325" rx="5" ry="5" width="140" height="18" />
        <rect x="5" y="354" rx="4" ry="4" width="110" height="15" />
        <rect x="5" y="380" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="405" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="430" rx="4" ry="4" width="170" height="15" />
        <rect x="5" y="455" rx="4" ry="4" width="100" height="15" />
        <rect x="130" y="475" rx="4" ry="4" width="15" height="15" />
    </ContentLoader>
    <ContentLoader style={{borderStyle:"solid", borderWidth:"1", borderColor:"lightgray", marginLeft:"7px", marginBottom: "7px"}} width={300} height={500} speed={3} backgroundColor={'#2788e8'} foregroundColor={'#f8f8ff'}>
        <rect x="5" y="28" rx="4" ry="4" width="190" height="26" />
        <rect x="5" y="65" rx="4" ry="4" width="70" height="14" />
        <rect x="5" y="98" rx="5" ry="5" width="290" height="218" />
        <rect x="5" y="325" rx="5" ry="5" width="150" height="18" />
        <rect x="5" y="354" rx="4" ry="4" width="100" height="15" />
        <rect x="5" y="380" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="405" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="430" rx="4" ry="4" width="170" height="15" />
        <rect x="5" y="455" rx="4" ry="4" width="100" height="15" />
        <rect x="130" y="475" rx="4" ry="4" width="15" height="15" />
    </ContentLoader>
    <ContentLoader style={{borderStyle:"solid", borderWidth:"1", borderColor:"lightgray", marginLeft:"7px", marginBottom: "7px"}} width={300} height={500} speed={3} backgroundColor={'#2788e8'} foregroundColor={'#f8f8ff'}>
        <rect x="5" y="28" rx="4" ry="4" width="190" height="26" />
        <rect x="5" y="65" rx="4" ry="4" width="70" height="14" />
        <rect x="5" y="98" rx="5" ry="5" width="290" height="218" />
        <rect x="5" y="325" rx="5" ry="5" width="150" height="18" />
        <rect x="5" y="354" rx="4" ry="4" width="100" height="15" />
        <rect x="5" y="380" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="405" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="430" rx="4" ry="4" width="170" height="15" />
        <rect x="5" y="455" rx="4" ry="4" width="100" height="15" />
        <rect x="130" y="475" rx="4" ry="4" width="15" height="15" />
    </ContentLoader>
    <ContentLoader style={{borderStyle:"solid", borderWidth:"1", borderColor:"lightgray", marginLeft:"7px", marginBottom: "7px"}} width={300} height={500} speed={3} backgroundColor={'#2788e8'} foregroundColor={'#f8f8ff'}>
        <rect x="5" y="28" rx="4" ry="4" width="220" height="26" />
        <rect x="5" y="65" rx="4" ry="4" width="80" height="14" />
        <rect x="5" y="98" rx="5" ry="5" width="290" height="218" />
        <rect x="5" y="325" rx="5" ry="5" width="150" height="18" />
        <rect x="5" y="354" rx="4" ry="4" width="130" height="15" />
        <rect x="5" y="380" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="405" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="430" rx="4" ry="4" width="170" height="15" />
        <rect x="5" y="455" rx="4" ry="4" width="100" height="15" />
        <rect x="130" y="475" rx="4" ry="4" width="15" height="15" />
    </ContentLoader>
    <ContentLoader style={{borderStyle:"solid", borderWidth:"1", borderColor:"lightgray", marginLeft:"7px", marginBottom: "7px"}} width={300} height={500} speed={3} backgroundColor={'#2788e8'} foregroundColor={'#f8f8ff'}>
        <rect x="5" y="28" rx="4" ry="4" width="230" height="26" />
        <rect x="5" y="65" rx="4" ry="4" width="80" height="14" />
        <rect x="5" y="98" rx="5" ry="5" width="290" height="218" />
        <rect x="5" y="325" rx="5" ry="5" width="140" height="18" />
        <rect x="5" y="354" rx="4" ry="4" width="110" height="15" />
        <rect x="5" y="380" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="405" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="430" rx="4" ry="4" width="170" height="15" />
        <rect x="5" y="455" rx="4" ry="4" width="100" height="15" />
        <rect x="130" y="475" rx="4" ry="4" width="15" height="15" />
    </ContentLoader>
    <ContentLoader style={{borderStyle:"solid", borderWidth:"1", borderColor:"lightgray", marginLeft:"7px", marginBottom: "7px"}} width={300} height={500} speed={3} backgroundColor={'#2788e8'} foregroundColor={'#f8f8ff'}>
        <rect x="5" y="28" rx="4" ry="4" width="270" height="26" />
        <rect x="5" y="65" rx="4" ry="4" width="105" height="14" />
        <rect x="5" y="98" rx="5" ry="5" width="290" height="218" />
        <rect x="5" y="325" rx="5" ry="5" width="130" height="18" />
        <rect x="5" y="354" rx="4" ry="4" width="150" height="15" />
        <rect x="5" y="380" rx="4" ry="4" width="100" height="15" />
        <rect x="5" y="405" rx="4" ry="4" width="80" height="15" />
        <rect x="5" y="430" rx="4" ry="4" width="170" height="15" />
        <rect x="5" y="455" rx="4" ry="4" width="100" height="15" />
        <rect x="130" y="475" rx="4" ry="4" width="15" height="15" />
    </ContentLoader>

    </div>
)

export default LoadingCards