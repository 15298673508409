const convertPaySchedule = num => {
    switch (num) {
        case 1:
            return 'monthly';
        case 2:
            return 'yearly';
        case 3:
            return 'monthly';
        case 4:
            return 'yearly';
        case 5:
            return 'monthly';
        case 6:
            return 'yearly';
        default:
            return '';
    }
}
export default convertPaySchedule;