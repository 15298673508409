import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Typography, Divider, FormControlLabel, Checkbox, Box, FormGroup } from '@material-ui/core'
import EditAccount from './EditAccount';
import subscriptionType from './helpers/subscriptionType';
import dateToString from './helpers/dateToString';
import { allPossibleCities } from './helpers/allPossibleCities';

const Account = ({user}) => { //subscriptionType
    const [edit, setEdit] = useState(false)
    const [subscription, setSubscription] = useState(null)
    const [defaultCitiesState, setDefaultCitiesState] = useState(user?.defaultCheckedCities ?? [])
    const [defaultCitiesUpdateSuccess, setDefaultCitiesUpdateSuccess] = useState(false)

    const toggleDefaultCitiesState = e => {
        const target = e.target.checked; // true or false
    
        const name = e.target.name; // === 'Guelph'
    
        if (target) {
          setDefaultCitiesState([...defaultCitiesState, name]);
        } else {
          setDefaultCitiesState(defaultCitiesState.filter(city => city !== name));
        }
      };

    function handleSetDefaultCities(e) {
        e.preventDefault()
        axios.post('/api/setDefaultCities', {newDefaultCities: defaultCitiesState, _id: user._id}).then(res => {
            console.log(res.data)
            if (res.status === 200) {
                setDefaultCitiesUpdateSuccess(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (user?.subscribed) {
            axios.get(`/stripe/subscription/${user.subscriptionId}`).then(res => {
                setSubscription(res.data)
        })
    }
         // eslint-disable-next-line
    }, [])

    const renderCities = () => {
        const cities = user?.subscriptionType > 4 ? allPossibleCities : user?.cities
        return cities.map(city => {
           return <FormControlLabel key={city} control={<Checkbox name={city} color="primary" checked={defaultCitiesState.includes(city)}/>} label={city} />
        })
    }
    

    const toggleEdit = () => {
        setEdit(!edit)
    }

    if (subscription) {
    return (
        <div>
            <Typography>Hi {user.firstName}</Typography>
            <br/>
            {user?.subscriptionType < 3 ? "" :
            
        <>
            <Typography><span style={{fontWeight: 'bold'}} >Default Checked Cities:</span></Typography>
            <Box>
                <FormGroup style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}} row onClick={e => toggleDefaultCitiesState(e)} >
                    {renderCities()}
                </FormGroup>
            </Box>            
            <Button color="primary" variant='contained' onClick={handleSetDefaultCities} >Set Default Checked Cities</Button>
            {defaultCitiesUpdateSuccess ? <Typography>Default cities updated successfully.</Typography> : ''}
        </>
    }
            <br/>
            <Typography><span style={{fontWeight: 'bold'}} >Subscription Start Date:</span>   {dateToString(new Date(subscription.current_period_start * 1000))}</Typography>
            <Typography><span style={{fontWeight: 'bold'}} >Subscription Renwal Date:</span> {dateToString(new Date(subscription.current_period_end * 1000))}</Typography>
            <Typography><span style={{fontWeight: 'bold'}} >Current Subscription Type:</span> {subscriptionType(user.subscriptionType)}</Typography>
            <Typography><span style={{fontWeight: 'bold'}} >Cities/Regions included in your subscription:</span> {user.cities ? user.cities.map(city => `${city}, `) : "All supported areas"}</Typography>


            <br/>
            <Button color="secondary" variant='contained' onClick={() => toggleEdit()} >Edit Account</Button>
            <br/>
            <br/>
            <Divider/>
            <br/>
            {edit ? <EditAccount user={user} subscription={subscription} /> : ''}
        </div>
    )
} else {
    return <div>You need to be subscribed before you can access this page.</div>
}
}

export default Account;