import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
//import axios from 'axios';



(async () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#f44336',
      },
      },
   }
  )
  //const PUBLISHABLE_TEST = 'pk_test_51IldeoLTKZayFihRS0VCSomk0yQCYPGKUBP2HZqInsE5hCktbu9iTuyr2iNOYD9HW4BxCyakMQGL6o4jHeT6v2lC00zX7lVS8y'
  const PUBLISHABLE_LIVE = 'pk_live_51IldeoLTKZayFihRdIR219cQenmWPYpwicBDDaawx37EPSajiiaQmBXCjVywsbywshsIW9rAIpeikwYLHHPF7vEA00Q9IpO5hg'
  const stripePromise = loadStripe(PUBLISHABLE_LIVE)
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <App />
        </Elements>
    </MuiThemeProvider>
,
  document.getElementById('root')
);

})()