import React from 'react'
//import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import LinkIcon from '@material-ui/icons/Link'
import dateToString from './helpers/dateToString';


const TrebTable = ({ filteredListingsByCity, toggleChecked, checked }) => {
    return (
      <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell>MLS</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Address</TableCell>
            <TableCell align="left">City</TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">Beds</TableCell>
            <TableCell align="center">Baths</TableCell>
            <TableCell align="center">List Date</TableCell>
            <TableCell align="center">Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredListingsByCity.map((l) => (
            <TableRow key={l.mls}>
              <TableCell component="th" scope="row">
              <Checkbox  name={l.mls} color="primary" checked={checked ? checked.includes(l.mls) : false} onClick={(e) => toggleChecked(e)}/>
              </TableCell>
              <TableCell align="center">{l.mls}</TableCell>
              <TableCell align="center">{l.price}</TableCell>
              <TableCell align="center">{l.address}</TableCell>
              <TableCell align="left">{l.city}</TableCell>
              <TableCell align="center">{l.type}</TableCell>
              <TableCell align="center">{l.beds}</TableCell>
              <TableCell align="center">{l.baths}</TableCell>
              <TableCell align="center">{dateToString(l.da)}</TableCell>
              <TableCell align="center">
                  <a href={l.link} target="_blank" rel="noreferrer">
                    <LinkIcon />
                  </a>
                 
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}

export default TrebTable

//  "proxy": "http://localhost:5000",