import React from 'react'
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'

function Copyright() {
    return (
      <div style={{ textAlign: 'center'}}>
        <Link to="/terms">Terms</Link>
         | 
        <Link to="/privacy">Privacy</Link>
      
        <Typography variant="body2" color="textSecondary" align="center">info@localtreb.com</Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {`Copyright © Local TREB ${new Date().getFullYear()}.`}
      </Typography>
      </div>
    );
  }

  export default Copyright