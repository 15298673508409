const changeType = type => {
    switch (type) {
        case 'Row / Townhouse':
            return 'Townhouse';
        case 'Apartment':
            return 'Apartment Condo';
        /* case 'Duplex':
            return 'Two-Unit';
        case 'Multi-Family':
            return 'Multi-Unit'; */
        case "": 
            return 'Split Level'
        default:
            return type;
    }
}
export default changeType;