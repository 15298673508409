import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom'
import React from 'react';

const Terms = () => {
    return (
        <div>
            <Typography variant='h3'>TERMS OF SERVICE</Typography>
            <br/>
            ----
            <br/>
            <Typography variant='h6'>OVERVIEW</Typography>            
            <Typography variant='h6'>SECTION 1 - WEBSITE TERMS</Typography>
            <Typography>
                By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
                You must not transmit any worms or viruses or any code of a destructive nature.
                A breach or violation of any of the Terms will result in an immediate termination of your Services.
            </Typography>
            <Typography variant='h6'>SECTION 2 - GENERAL CONDITIONS</Typography>
            <Typography>
                We reserve the right to refuse service to anyone for any reason at any time.
                You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
                The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
            </Typography>
            <Typography variant='h6'>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</Typography>
            <Typography>
                We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
                This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. Listing dates in particular are estimated and not always correct. We aim to update the contents of this site multiple times per day, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
                We are simply displaying information from other sources, and us such, assume no liability for it's accuracy. Always check with the listing Agent to confirm
            </Typography>
            <Typography variant='h6'>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</Typography>
            <Typography>
                Prices for our products are subject to change without notice.
                <br/>
                We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                <br/>
                We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service
            </Typography>
            <Typography variant='h6'>SECTION 5 - SERVICES</Typography>
            <Typography>
                We will make every effort to only display accurate information on our website. We acknowledge that some listings are "False Positives", meaning they are displayed despite being listed on your local board. While we do not believe it's possible that we will miss displaying any
                <br/>
                TREB listings that are NOT listed on your local Real Estate Board, we cannot fully guarantee that we will not miss any. 
            </Typography>
            <Typography variant='h6'>SECTION 6 - EXTERNAL LINKS</Typography>
            <Typography>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</Typography>
            <Typography variant='h6'>SECTION 7 - PERSONAL INFORMATION</Typography>
            <Typography>Your submission of personal information through the store is governed by our <Link to='/privacy' >Privacy Policy</Link></Typography>
            <Typography variant='h6'>SECTION 8 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</Typography>
            <Typography>
                We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
                <br/>
                We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
                <br/>
                You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice 
                to you. You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service 
                are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or 
                implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement. In 
                no case shall Local TREB be liable for any injury, loss,
                claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, 
                loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of 
                the service, or for any other claim related in any way to your use of the service, including, but not limited to, any 
                errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, 
                or otherwise made available via the service, even if advised of their possibility. 
            </Typography>
            <Typography variant='h6'>SECTION - 9 INDEMNIFICATION</Typography>
            <Typography>
                You agree to indemnify, defend and hold harmless Local TREB and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.SECTION 15 - SEVERABILITY
                <br/>
                In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
            </Typography>
            <Typography variant='h6'>SECTION 10 - TERMINATION</Typography>
            <Typography>
                The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                <br/>
                These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by going to the Account tab and clicking 'Cancel Subscription' (if you have an active subscription) and then clicking 'Delete Account'.If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
                <br/><br/>
                If you cancel your account midway through your billing cycle (either month or year depending on your subscription), your subscription will remain active until the end of that month. You will not recieve a refund for the pro-rated amount of days left in that billing cycle, but you will not be charged again. 
            </Typography>
            <Typography variant='h6'>SECTION 11 - ENTIRE AGREEMENT</Typography>
            <Typography>
                The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                <br/>
                These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
            </Typography>
            <Typography variant='h6'>SECTION 12 - GOVERNING LAW</Typography>
            <Typography>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Ontario, Canada</Typography>
            <Typography variant='h6'>SECTION 13 - CHANGES TO TERMS OF SERVICE</Typography>
            <Typography>
                You can review the most current version of the Terms of Service at any time at this page.
                <br/>
                We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
            </Typography>
            <Typography variant='h6'>SECTION 14 - CONTACT INFORMATION</Typography>
            <Typography>Questions about the Terms of Service should be sent to us at info@localtreb.com.</Typography>
        </div>
    )
}

export default Terms;