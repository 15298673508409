import React from 'react';
import { Typography } from '@material-ui/core';

const About = () => {

    return (
        <div>
            <Typography variant="h2" align='center'>About</Typography>
        <div>
            <Typography variant="h6" color="textSecondary" component="p">
            Local TREB provides you with a list of all the local properties for sale in your area that are ONLY on TREB. 
            It excludes any property listed on both TREB and your local board. 
            <br/>
            <br/>
            For a complete list of supported areas, please see the Pricing page. Email info@localtreb.com if you're unsure what's included. 
            <br/>
            <br/>
            The issue of out of town realtors listing properties only on TREB is only going to get worse, which is why we created this site. Inventory is at an all time low, so as responsible realtors, we can't afford to miss anything.
            <br/>
            <br/>
            I've been monitoring this issue for quite some time. Most cities have 10 - 15% of active listings only listed on TREB. 
            <br/>
            <br/>
            Waterloo is by far the worst, with 30-40% of all active listings only listed on TREB. This is over 800 listings per year. Kitchener is about 500 per year. Guelph and Cambridge are around 300 each.
            <br/>
            <br/>
            This amounts to thousands of listings which are under marketed that you could and should be taking advantage of. 
            <br/>
            <br/>
            We are not permitted to include any listing info besides what it publicly available on sites such as realtor.ca, but everything is linked directly to realtor.ca so you can easily get in touch with the listing agent. 
            <br/>
            <br/>
            We only use Facebook or Google for sign in. We do not request any special permission or data, just your name and email. Facebook includes your profile picture but we do not use or store it in any way. 
            <br/>
            <br/>
            We use <a target="_blank" rel="noreferrer" href="https://stripe.com/en-ca/customers">Stripe</a> to process payments for subscription, so your credit card is never read or stored anywhere on our servers. Stripe is one of the largest and most secure payment providers in the world.
            </Typography>
        </div>
        </div>
    )
}

export default About;