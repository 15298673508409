const subscriptionType = num => {
    switch (num) {
        case 1:
            return 'Single - Monthly';
        case 2:
            return 'Single - Annual';
        case 3:
            return 'Triple - Monthly';
        case 4:
            return 'Triple - Annual';
        case 5:
            return 'All cities and regions - Monthly'
        case 6:
            return 'All cities and regions - Annual';
        default:
            return '';
    }
}
export default subscriptionType;