//Create a date with time in readable formate
import dateToString from "./dateToString";

export default function dateToTime(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours()
    const hoursNum = parseInt(hours)
    let ampm = hoursNum > 11 ? 'PM' : 'AM'
    hours = hoursNum > 12 ? hoursNum - 12 : hours
    const minutes = date.getMinutes().toString().padStart(2, 0)
    const monthAndDate = dateToString(dateString);
    return `${hours}:${minutes} ${ampm} ${monthAndDate}`
}