import React, { useState }from 'react';
import { GoogleMap, Marker, LoadScript, InfoWindow } from '@react-google-maps/api';

//, toggleCheckedMap 
const Map = ({ filteredListingsByCity,  checked}) => {

    const [ selected, setSelected ] = useState(filteredListingsByCity[0]);
    const onSelect = (item) => {
        setSelected(item);
        }

    const containerStyle = {
        width: '100%',
        height: '50vh'
      };
      
      const center = {
        lat: selected.latitude,
        lng: selected.longitude
      };

      const renderWindowContent = (selected) => {
          if(selected) {
            return (
                <p>
                  <a href={selected.link} target="_blank" rel="noopener noreferrer">
                <b>{selected.address.substring(0,14)}</b>
                
                <br/>
                {/* <img src={`data:image/png;base64, ${selected.cropped || selected.pic}`} alt={selected.mls} height="100%" width="100px" /> */}
                <img src={selected.googlePic} alt={selected.mls} height="100%" width="100px" />
                </a>
                <br/>
                {selected.price}
                <br/>
                {selected.type}
                <br/>
                {selected.beds} beds
                <br/>
                {selected.baths} baths
                <br/>
                {selected.mls}
                </p>

              )
          } else {
              return ""
          }
          
         
        }

    const renderMarkerOptions = (listing) => {
      if (checked.includes(listing.mls)) {
        return {icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
      }
    }


    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap 
            mapContainerStyle={containerStyle}
            center={center}
            zoom={11}
          >
            { filteredListingsByCity.map((listing) => {
                return (
                    <Marker 
                        key={listing.address} 
                        title={listing.address}
                        position={ {lat: listing.latitude, lng: listing.longitude}} 
                        onClick={() => onSelect(listing)} 
                       // onRightClick={(e, v) => toggleCheckedMap(e, v)}
                        options={renderMarkerOptions(listing)}
                        name={listing.mls}
                    />
                )
            }) 
            }
           <InfoWindow 
                position={{lat:43.558048, lng: -80.3052845} && {lat: selected.latitude , lng: selected.longitude} } 
                clickable={true} 
                onCloseClick={() => setSelected(filteredListingsByCity[0])}
                width={50}
            >
            {renderWindowContent(selected)}

        </InfoWindow>              
          </GoogleMap>
        </LoadScript>
    )



        }
//  {{ lat: listing.latitude, lng: listing.longitude } && (
export default Map;
//               {lgn: selected.longitude, lat: selected.latitude} && (
    //|| {lat: 43.558048, lng: -80.3052845}